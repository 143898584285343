<template>
  <v-row
    id="login"
    class="layer primary"
    align="center"
    justify="center"
    no-gutters
  >

    <alerts absolute/>

    <v-sheet
      color="transparent"
      class="ma-4 pa-4"
      width="100%"
      max-width="400"
    >

      <logo
        class="mb-4"
        height="100"
        color="dark"
        show-version
      />

      <v-card class="pa-4 mb-6">

        <v-form
          ref="form"
          @submit.prevent="onSubmit"
        >

          <text-field
            v-model="username"
            :label="$t('inputs.alias.label')"
            :rules="rules.username"
            autocomplete="username"
            background-color="white"
            fluid
          />

          <password-field
            v-model="password"
            :label="$t('inputs.password.label')"
            :rules="rules.password"
            type="password"
            autocomplete="current-password"
            background-color="white"
            fluid show-password
          />

          <btn
            type="submit"
            class="caption mt-2 mb-3"
            color="black"
            :loading="loading"
            block dark
          >
            {{ $t('btn.login') }}
          </btn>

        </v-form>
      </v-card>

      <router-link class="caption black--text" :to="$lp('/recovery-pass')">
        {{ $t('btn.forgotPass') }}
      </router-link>

    </v-sheet>
  </v-row>
</template>

<script>
import Alerts from '@/components/Alerts';
import TextField from '@/components/form/TextField';
import PasswordField from '@/components/form/PasswordField';
import { required } from '@/utils/rules';
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  head: vm => ({ title: vm.$i18n.t('login.title') }),
  auth: 'guess',
  components: { TextField, PasswordField, Alerts },
  data: () => ({
    loading: false,
    username: '',
    password: '',
    rules: {
      username: [],
      password: []
    }
  }),
  watch: {
    username: 'cleanRules',
    password: 'cleanRules'
  },
  methods: {
    ...mapActions( 'auth', [ 'login', 'fetchUser' ]),
    ...mapActions( 'app', [ 'error' ]),
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      return this.$refs.form && this.$refs.form.reset();
    },
    cleanRules() {
      this.rules.username = [];
      this.rules.password = [];
    },
    applyRules() {
      this.rules.username = [ required( this.$t('inputs.alias.rules.required')) ];
      this.rules.password = [ required( this.$t('inputs.password.rules.required')) ];
    },
    onSubmit() {
      this.applyRules();
      this.$nextTick(() => {
        if ( this.validate()) {
          const { username, password } = this;
          this.loading = true;
          this.login({ username, password }).then(() => {
            return this.fetchUser();
          }).catch( err => {
            console.log( err );
            this.error({ message: this.$t('alerts.authError') });
            this.password = '';
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    }
  }
}
</script>
