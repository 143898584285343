<template>
  <v-slide-y-transition
    tag="div"
    :class="classes"
    :style="{ zIndex }"
    group
  >
    <div
      v-for="alert in alerts"
      :key="alert.id"
    >
      <alert
        class="mb-2"
        max-width="720"
        close-text="Cerrar alerta"
        :type="alert.type"
        :timeout="alert.persistent ? 0 : (alert.timeout || timeout)"
        :value="alert.show"
        :dismissible="dismissible"
        :close-icon="closeIcon"
        :dense="dense"
        @input="removeAlert( alert )"
      >
        <div v-html="alert.message"/>
      </alert>
    </div>
  </v-slide-y-transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Alert from './Alert';

export default {
  components: { Alert },
  props: {
    timeout: {
      type: [ Number, String ],
      default: 5000
    },
    dismissible: {
      type: Boolean,
      default: true
    },
    closeIcon: String,
    absolute: Boolean,
    fixed: Boolean,
    dense: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: [ Number, String ],
      default: 5
    }
  },
  methods: mapMutations( 'app', [ 'removeAlert' ]),
  computed: {
    ...mapState( 'app', [ 'alerts' ]),
    classes() {
      return {
        'alerts-group': 1,
        'absolute': this.absolute,
        'fixed': this.fixed
      }
    }
  }
};
</script>

<style>
.alerts-group {
  padding: 8px;
  text-align: center;
}
.alerts-group.absolute,
.alerts-group.fixed {
  top: 0;
  left: 0;
  right: 0;
}
.alerts-group .v-alert {
  display: inline-block;
  text-align: left;
}
</style>
