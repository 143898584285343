import strongPasswordValidator from 'validator/lib/isStrongPassword';
import emailValidator from 'validator/lib/isEmail';
import { getMoment } from './index';

export const required = ( message = 'Field is required.' ) => {
  return v => Array.isArray(v)
    ? !!v.length || message
    : !!v || v === 0 || message;
}

export const min = ( min, message ) => {
  return v => {
    return v == null || v >= min || message || ( 'The number cannot be less than ' + min );
  }
}

export const max = ( max, message ) => {
  return v => {
    return v == null || v <= max || message || ( 'The number cannot be greater than ' + max );
  }
}

export const minLength = ( length, message ) => {
  length == null ? 3 : length;
  return v => {
    return !v || v.length >= length || message || ( 'The number of characters cannot be less than ' + length );
  }
}

export const maxLength = ( length, message ) => {
  length == null ? 3 : length;
  return v => {
    return !v || v.length >= length || message || ( 'The number of characters cannot be greater than ' + length );
  }
}

export const minMatch = ( regex, length, message ) => {
  return v => {
    return (
      !v
      || typeof v === 'string' && ( v.split(regex).length - 1 ) >= length
      || message
      || ( 'The number of occurrences cannot be less than ' + length )
    )
  }
}

export const maxMatch = ( regex, length, message ) => {
  return v => {
    return (
      !v
      || typeof v === 'string' && ( v.split(regex).length - 1 ) <= length
      || message
      || ( 'The number of occurrences cannot be greater than ' + length )
    )
  }
}

export const sameAs = ( key, data, message ) => {
  data = data || {};
  return v => {
    return !data[key] || data[key] === v || message || ( 'The field is not equal that ' + key );
  };
}

export const sameDate = ( date, format, message ) => {
  date = getMoment( date, format );
  return v => {
    v = getMoment( v, format );
    if ( !date || !v ) return true;
    return v.isSame( date ) || message || 'Is not same';
  }
}

export const beforeDate = ( date, format, message ) => {
  date = getMoment( date, format );
  return v => {
    v = getMoment( v, format );
    if ( !date || !v ) return true;
    return v.isSameOrBefore( date ) || message || 'Is not same';
  }
}

export const afterDate = ( date, format, message ) => {
  date = getMoment( date, format );
  return v => {
    v = getMoment( v, format );
    if ( !date || !v ) return true;
    return v.isSameOrAfter( date ) || message || 'Is not same';
  }
}

export const isBetweenDate = ( date1, date2, format, message ) => {
  date1 = getMoment( date1, format );
  date2 = getMoment( date2, format );
  return v => {
    v = getMoment( v, format );
    if ( !date1 || !date2 || !v ) return true;
    return v.isBetween( date1, date2 ) || message || 'Is not between';
  }
}

export const isStrongPassword = ( message, options ) => {
  /*{
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
    returnScore: false,
    pointsPerUnique: 1,
    pointsPerRepeat: 0.5,
    pointsForContainingLower: 10,
    pointsForContainingUpper: 10,
    pointsForContainingNumber: 10,
    pointsForContainingSymbol: 10
  }*/
  options = { ...options, returnScore: false };
  return v => !v || strongPasswordValidator( v, options) || message || 'Is not strong password';
}

export const isEmail = ( message, options ) => {
  /*{
    allow_display_name: false,
    require_display_name: false,
    allow_utf8_local_part: true,
    require_tld: true,
    allow_ip_domain: false,
    domain_specific_validation: false,
    blacklisted_chars: ''
  }*/
  return v => !v || emailValidator( v, options) || message || 'Is not e-mail.';
}

const PROTOCOL_REG_EXP = /^https?:\/\/\S+$/;

export const isLink = message => {
  return v => !v || !!PROTOCOL_REG_EXP.test(v) || message || 'Is not valid link.';
}
