<template>
  <v-alert v-model="show" v-bind="$attrs">
    <template v-for="name in slots" :slot="name">
      <slot :name="name"/>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: 'alert',
  inheritAttrs: false,
  props: {
    value: null,
    timeout: {
      type: [ Number, String ],
      default: 0
    }
  },
  data() {
    return {
      show: this.value,
      slots: [ 'default', 'append', 'close', 'prepend' ]
    }
  },
  watch: {
    show( value ) {
      this.applyTimeout();
      this.$emit( 'input', value );
    },
    value( value ) {
      this.show = value;
    }
  },
  methods: {
    applyTimeout() {
      clearInterval( this.interval );
      const timeout = parseInt( this.timeout ) || 0;
      if ( timeout && this.show ) {
        this.interval = setTimeout(() => this.show = false, timeout );
      }
    }
  },
  mounted() {
    this.applyTimeout();
  }
};
</script>
